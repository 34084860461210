import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import projectServices from "../../../API/projectServices";
import styles from "../AddProject/AddProject.module.css";
import trash from "../../../Assets/trash.svg";
import upload from "../../../Assets/upload.svg";
import DatePicker from "react-datepicker"; // Import Datepicker
import "react-datepicker/dist/react-datepicker.css"; // Import Datepicker's CSS

export default function EditProject() {
    const { id: projectId } = useParams();
    const [heading, setHeading] = useState("");
    const [description, setDescription] = useState("");
    const [links, setLinks] = useState([]);
    const [existingFiles, setExistingFiles] = useState([]);
    const [newFiles, setNewFiles] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date()); // New state for the date
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    const fetchProjectData = async () => {
        try {
            const { data } = await projectServices.getProject(projectId);
            const project = data.project;
            setHeading(project.heading || "");
            setDescription(project.description || "");
            setLinks(project.links || []); // Links default to an empty array
            setExistingFiles(project.files || []);
            setSelectedDate(project.date ? new Date(project.date) : new Date()); // Initialize date
        } catch (error) {
            console.error("Error fetching project data:", error);
        }
    };

    const handleLinkChange = (index, value) => {
        const updatedLinks = [...links];
        updatedLinks[index] = value;
        setLinks(updatedLinks);
    };

    const addLink = () => {
        setLinks([...links, ""]); // Add an empty link field
    };

    const removeLink = (index) => {
        const updatedLinks = links.filter((_, i) => i !== index);
        setLinks(updatedLinks);
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setNewFiles([...newFiles, ...selectedFiles]);
        e.target.value = "";
    };

    const removeNewFile = (index) => {
        const updatedFiles = newFiles.filter((_, i) => i !== index);
        setNewFiles(updatedFiles);
    };

    const removeExistingFile = async (fileName) => {
        try {
            await projectServices.deleteFileFromProject(projectId, fileName);
            setExistingFiles(existingFiles.filter((file) => file.fileName !== fileName));
        } catch (error) {
            console.error("Error removing file:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            if (newFiles.length > 0) {
                const formData = new FormData();
                newFiles.forEach((file) => formData.append("files", file));
                await projectServices.uploadFileToProject(projectId, formData);
            }

            const projectData = {
                heading,
                description,
                links,
                date: selectedDate.toISOString(), // Send the date as an ISO string
            };
            await projectServices.updateProject(projectId, projectData);

            alert("Project updated successfully!");
            setNewFiles([]);
            fetchProjectData();
            navigate("/admin/projects");
        } catch (error) {
            console.error("Error updating project:", error);
            alert("Failed to update project.");
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        fetchProjectData();
    }, [projectId]);

    return (
        <div className={styles.container}>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.form__group}>
                    <label htmlFor="heading">Heading</label>
                    <input
                        type="text"
                        id="heading"
                        name="heading"
                        placeholder="Write your heading"
                        value={heading}
                        onChange={(e) => setHeading(e.target.value)}
                        required
                    />
                </div>
                <div className={styles.form__group}>
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        name="description"
                        placeholder="Write your description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    ></textarea>
                </div>
                <div className={styles.form__group}>
                    <label htmlFor="date">Date</label>
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        dateFormat="yyyy-MM-dd"
                        className={styles.datepicker} // Optional: add custom styles
                    />
                </div>
                <div className={styles.form__group}>
                    <div className={styles.label__row}>
                        <label>Links</label>
                        <button type="button" className={styles.add__link} onClick={addLink}>
                            Add Link
                        </button>
                    </div>
                    {links.map((link, index) => (
                        <div key={index} className={styles.link__wrapper}>
                            <input
                                type="url"
                                placeholder="Enter link"
                                value={link}
                                onChange={(e) => handleLinkChange(index, e.target.value)}
                            />
                            <button
                                type="button"
                                className={styles.trash__btn}
                                onClick={() => removeLink(index)}
                            >
                                <img src={trash} alt="Delete" />
                            </button>
                        </div>
                    ))}
                </div>
                <button type="submit" className="primary__btn" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : "Update Project"}
                </button>
            </form>

            <div className={styles.right__section}>
                <div className={styles.file__upload}>
                    <label htmlFor="fileUpload" className={styles.upload__box}>
                        <div className={styles.upload__icon}>
                            <img src={upload} alt="Upload Icon" />
                            <span>Click or drag files to upload</span>
                        </div>
                        <input
                            type="file"
                            id="fileUpload"
                            className={styles.upload__input}
                            multiple
                            onChange={handleFileChange}
                        />
                    </label>
                </div>
                <div className={styles.uploaded__files}>
                    <h4>Existing Files</h4>
                    {existingFiles.map((file, index) => (
                        <div key={index} className={styles.file__item}>
                            <p>{file.fileName}</p>
                            <button
                                type="button"
                                className={styles.trash__btn}
                                onClick={() => removeExistingFile(file.fileName)}
                            >
                                <img src={trash} alt="Remove file" />
                            </button>
                        </div>
                    ))}
                </div>
                <div className={styles.uploaded__files}>
                    <h4>New Files</h4>
                    {newFiles.map((file, index) => (
                        <div key={index} className={styles.file__item}>
                            <p>{file.name}</p>
                            <button
                                type="button"
                                className={styles.trash__btn}
                                onClick={() => removeNewFile(index)}
                            >
                                <img src={trash} alt="Remove file" />
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}