import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import adminServices from "../../../API/adminServices";
import styles from "./FacebookFetch.module.css";
import arrow from "../../../Assets/arrow.svg";
import fbLogo from "../../../Assets/fb_logo.png";

const FacebookFetch = ({ setDescription, setLinks }) => {
    const [pages, setPages] = useState([]);
    const [posts, setPosts] = useState({});
    const [activePageId, setActivePageId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (window.location.hash === "#_=_") {
            window.history.replaceState({}, document.title, window.location.pathname);
        }

        const setTokenFromParams = async () => {
            const queryParams = new URLSearchParams(location.search);
            const token = queryParams.get("token");

            if (token) {
                try {
                    setLoading(true);
                    await adminServices.setCookie({ cookieName: "fbToken", cookie: token });
                    queryParams.delete("token");
                    const newUrl = `${window.location.origin}${window.location.pathname}`;
                    window.history.replaceState({}, document.title, newUrl);
                } catch (err) {
                    console.error("Failed to set token from params:", err.message);
                    setError(err.message || "Failed to set token.");
                } finally {
                    setLoading(false);
                }
            }
        };

        setTokenFromParams();
    }, [location]);

    const fetchPages = async () => {
        setLoading(true);
        setError(null);
        try {
            const fetchedPages = await adminServices.fetchFacebookPages();
            setPages(fetchedPages);
        } catch (err) {
            console.error("Error fetching Facebook pages:", err.message);
            setError(err.message || "Failed to fetch pages.");
        } finally {
            setLoading(false);
        }
    };

    const fetchPosts = async (pageId) => {
        setLoading(true);
        setError(null);
        try {
            setPosts({});
            setActivePageId(null);

            const selectedPage = pages.find((page) => page.id === pageId);

            if (!selectedPage || !selectedPage.access_token) {
                throw new Error("Page Access Token is missing.");
            }

            const fetchedPosts = await adminServices.fetchFacebookPagePosts(pageId, selectedPage.access_token);

            setPosts({
                [pageId]: fetchedPosts,
            });
            setActivePageId(pageId);
        } catch (err) {
            console.error("Error fetching posts:", err.message);
            setError(err.message || "Failed to fetch posts.");
        } finally {
            setLoading(false);
        }
    };

    const handleFacebookLogin = () => {
        adminServices.redirectToFacebookLogin();
    };

    const selectPost = async (post) => {
        setLoading(true);
        setError(null);

        try {
            const selectedPage = pages.find((page) => page.id === activePageId);

            if (!selectedPage?.access_token) {
                throw new Error("Page access token is missing.");
            }

            if (!post.message && !post.attachments) {
                setError("This post has no content to fetch.");
                return;
            }

            const postData = await adminServices.downloadPostMedia(post.id, selectedPage.access_token);

            const description = postData.description || post.message || "";
            const linkRegex = /(https?:\/\/[^\s]+)/g;
            const extractedLinks = description.match(linkRegex) || [];
            const cleanedDescription = description.replace(linkRegex, "").trim();

            const combinedLinks = [...(postData.links || []), ...extractedLinks].filter(
                (link) => !postData.media.includes(link)
            );

            // Update parent state
            setDescription(cleanedDescription);
            setLinks(combinedLinks);

            // Navigate to "Add Project" view
            navigate("/admin/projects/new");
        } catch (err) {
            console.error("Error selecting post:", err.message);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            {loading && <p>Loading...</p>}
            <h1 className={styles.title}>
                Fetch From <span className={styles.facebook}>Facebook</span>
            </h1>
            <div className={styles.actions}>
                <button className={styles.facebookBtn} onClick={handleFacebookLogin}>
                    <img src={fbLogo} alt="Facebook Logo" className={styles.facebookLogo}/>
                    <span>Login with Facebook</span>
                </button>
                <button className="primary__btn" onClick={fetchPages}>
                    Refresh Pages
                </button>
            </div>
            {error && <p className={styles.error}>Error: {error}</p>}
            <div className={styles.content}>
                <div className={styles.pages}>
                    <h2>Pages</h2>
                    {pages.length > 0 ? (
                        <ul className={styles.pageList}>
                            {pages.map((page) => (
                                <li key={page.id} className={styles.pageItem}>
                                    <button className={styles.pageButton} onClick={() => fetchPosts(page.id)}>
                                        {page.name}
                                        <img className={styles.arrow} src={arrow} alt="arrow"/>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No pages found or not logged in.</p>
                    )}
                </div>
                <div className={styles.posts}>
                    <h2>Posts</h2>
                    {Object.keys(posts).length > 0 ? (
                        Object.entries(posts).map(([pageId, pagePosts]) => (
                            <div className={styles.posts__list} key={pageId}>
                                {pagePosts.map((post, index) => (
                                    <div key={index} className={styles.post}>
                                        <div className={styles.wrapper}>
                                            <p className={styles.postMessage}>
                                                {post.message || "No message or content provided."}
                                            </p>
                                            <button
                                                className={styles.selectPostButton}
                                                onClick={() => selectPost(post)}
                                            >
                                                Select Post
                                            </button>
                                        </div>
                                        {post.full_picture && (
                                            <img
                                                src={post.full_picture}
                                                alt="Post content"
                                                className={styles.postImage}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <p>Select a page to view posts.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FacebookFetch;
